.option-con {
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 4px;
  // font-size: calc(0.3vw + 10px);
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: 4px;
}

.option-btn {
  border: solid;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  margin: 0 0.4rem;
  cursor: pointer;
  background-color: white;
}

.option-btn-selected {
  background-color: antiquewhite;
}

.option-btn:hover {
  background-color: antiquewhite;
}

.btn-green{
  background: #216970;
}
.btn-green:hover{
  background: #306166;
}
.home-logo {
  margin: 0 auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.tel a:link{
  color: #0a58ca;
}
.wrapper {
  &[data-load="true"] {
    opacity: 0;
    visibility: hidden;
    transition: 0.65s;
  }

  &[data-load="false"] {
    opacity: 0.975;
    visibility: visible;
  }
  > {
    img {
      animation: loading 1.5s infinite alternate;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.Embed {
  cursor: default !important;
}

.home {
  &.banner-con {
    // height: calc(100vh - 184px);
    overflow-y: hidden;
  }
  &.bg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    // object-fit: cover;
  }
  &.title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    > {
      h1 {
        font-size: clamp(1.5rem, 4vw, 5rem);
      }
      h3 {
        // font-size: clamp(0.25rem, 2vw, 1rem);
      }
    }
  }
  &.sub-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    > {
      h1 {
        font-size: clamp(1.5rem, 4vw, 5rem);
      }
      h3 {
        // font-size: clamp(0.25rem, 2vw, 1rem);
      }
    }
  }
}

.info-bg {
  // object-position: 0 -50px;
}

// .swiper-slide{
//   min-width:fit-content!important;
// }

.intro-con {
  margin-top: -132px;
  padding: {
    top: 162px;
  }
}

.info {
  &.title {
    position: absolute;
    top: calc(50%);
    transform: translateY(calc(-50%));
    right: 0;
    > {
      h1 {
        font-size: clamp(1.5rem, 4vw, 5rem);
      }
    }
  }
}

.pl-50{
  padding-left: 10rem;
}

.btn-booking{
    border: 1px dotted;
    padding: 6px 6px;
    border-radius: 10px;
}

.title-con {
  font-size: calc(1.25vw + 20px);
}

.transform-center-y {
  top: 50%;
  transform: translateY(-50%);
}

.transform-center-x {
  left: 50%;
  transform: translateX(-50%);
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.home-grid {
  display: grid;
  width: 100%;
  // grid-template-columns: repeat(2, minmax(200px, 300px));
}

.info-con {
  &.left {
    justify-content: flex-end;
  }
  &.right {
    justify-content: flex-start;
  }
  &.photo {
    max-width: 350px;
    width: 50%;
    object-fit: cover;
    max-height: 100%;
  }
}

@media (max-width: 1322px) {
  .info-con {
    &.left {
      justify-content: center;
    }
    &.right {
      justify-content: center;
    }
    &.photo {
      max-width: inherit;
    }
  }
}



// @media (max-width:768px){
//   .home {
//     &.title {
//       top: 100%;
//     }
//   }

// }
